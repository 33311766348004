import React from 'react';

import { EquivalentDocGrid } from '../components/tabsHome';

/**
 * * Obtiene los tabs para los documentos equivalentes.
 * @returns {Array<Object>} - Array de objetos que representan los tabs.
 */
export const getTabsEquivalentDoc = () => {
  const createEquivalentDocGridComponent = (edit = false) => (
    <EquivalentDocGrid edit={edit} />
  );

  return [
    {
      title: 'Emitidas',
      component: createEquivalentDocGridComponent(),
    },
    {
      title: 'Editadas',
      component: createEquivalentDocGridComponent(true),
    },
  ];
};
