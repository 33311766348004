import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Grid, makeStyles } from '@material-ui/core';

import {
  editErrorDataModal,
  // errorDataModal,
  AnnulmentNoteModal,
  initialModalData,
  mailErrorDataModal,
  NoteTypeSelectionModal,
  RenderIconButton,
} from './';

import { getCompanyAction } from '../../../../actions/basicDataActions';
import {
  // changeEquivalentDocComponentAction,
  UpdateEquivalentDocAction,
} from '../../../../actions/equivalentDocAction';

// import FileModalComponent from "../../../../components/modal/fileModal.component";
import ModalFileForm from '../../../invoice/modal-file-form.component';
import ResponseModal from '../../../../components/modal/responseModal.component';
import SimpleModal from '../../../../components/modal/simpleModal.component';

import EditIcon from '@material-ui/icons/Edit';
// import VisibilityIcon from "@material-ui/icons/Visibility";
import EmailIcon from '@material-ui/icons/Email';
import imageMoney from '../../../../images/Icon-Shape.png';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { ConfirmAlert, WarningAlert } from '../../../../helpers/alert.helpers';
import { handleDocumentState } from '../../../../helpers/updateStateDocument';
import { getNameChangeState, stateInvoice } from '../../utils';

import { MESSAGE_NEXT_CONFIRM } from '../../../../config/config';

import { warningToast } from '../../../../helpers/toast.helpers';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '&:hover': {
      color: '#2CC63E',
    },
  },
  root: { height: '100%', background: '#393939' },
  iconContainer: {
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  textIconButton: {
    fontFamily: 'Muli',
    fontSize: '10px',
    fontWeight: '700',
    letterSpacing: '0',
    lineHeight: '18px',
    textAlign: 'center',
  },
}));

// const initialStateFile = {
//   Documento: "",
//   TipoDocumento: "",
//   CodigoUnico: "",
//   Transacion: Math.random(),
// };

export const FooterDetail = () => {
  const classes = useStyles();

  // const [file, setFile] = useState(initialStateFile);
  // const [openModalFile, setOpenModalFile] = useState(false);
  const [mailData, setMailData] = useState({});
  const [modalData, setModalData] = useState(initialModalData);
  const [openModalAnnulment, setOpenModalAnnulment] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalMail, setOpenModalMail] = useState(false);

  const dispatch = useDispatch();

  const {
    equivalentDocDetail,
    loadingStateEquivalentDoc,
    loadingStateEquivalentDocPaid,
  } = useSelector((state) => state.equivalentDocReducer);
  const { requestCompany } = useSelector((state) => state.basicDataReducer);
  const { sendmail } = useSelector((state) => state.invoiceReducer);

  const {
    anulacion,
    codigoUnico,
    correoElectronico,
    estadoId,
    favorito,
    id,
    nombreCliente,
    numero,
  } = equivalentDocDetail;

  useEffect(() => {
    dispatch(getCompanyAction(true));
  }, [dispatch]);

  useEffect(() => {
    cleanMail();
  }, [sendmail]);

  /**
   * * Limpia los datos del correo electrónico y cierra el modal.
   */
  const cleanMail = () => {
    setMailData({});
    setOpenModalMail(false);
  };

  /**
   * * Cambia el estado de favorito de un documento equivalente.
   * @param {Event} e - Evento que desencadenó la acción.
   * @returns {void}
   */
  const changeStateFavorite = (e) => {
    e.stopPropagation();
    handleDocumentState({
      actionFunction: UpdateEquivalentDocAction,
      currentState: favorito,
      dispatchFunction: dispatch,
      documentId: id,
      isFavorite: favorito,
      operation: 'FAVORITO',
    });
  };

  /**
   * * Cambia el estado de pago de un documento equivalente.
   * @returns {void}
   */
  const handlePayDocument = () => {
    /**
     * * Cambia el estado del documento.
     * @param {boolean} confirmed - Indica si se quiere realizar el cambio.
     * @returns {void}
     */
    const changeStatus = (confirmed) => {
      if (confirmed) {
        handleDocumentState({
          actionFunction: UpdateEquivalentDocAction,
          currentState: estadoId === '01',
          dispatchFunction: dispatch,
          documentId: id,
          isFavorite: favorito,
          operation: 'PAGADO',
        });
      }
    };

    if (anulacion) {
      WarningAlert(
        'Upsss...!',
        'El Documento Equivalente se encuentra anulado.'
      );
    } else {
      const messageText =
        'Actualmente el Documento Equivalente se encuentra en estado "' +
        getNameChangeState(equivalentDocDetail) +
        '" ¿Desea cambiar el estado del Documento Equivalente a "' +
        stateInvoice(equivalentDocDetail) +
        '"?';

      ConfirmAlert(messageText, changeStatus, MESSAGE_NEXT_CONFIRM);
    }
  };

  /**
   * * Maneja la apertura del modal de archivo.
   * @function
   * @returns {void}
   */
  // const handleOpenModalFile = () => {
  //   if (!!codigoUnico) {
  //     setFile((prevState) => ({
  //       ...prevState,
  //       TipoDocumento: tipodocumentoentidad,
  //       Documento: requestCompany.documento,
  //       CodigoUnico: codigoUnico,
  //       Transacion: Math.random(),
  //     }));
  //     setOpenModalFile(true);
  //   } else {
  //     setModalData(errorDataModal);
  //   }
  // };

  /**
   * * Cierra Modal, Notificación certificado no valido
   *  @function
   * @returns {void}
   */
  const handleResponseModal = () => {
    setModalData(initialModalData);
  };

  /**
   * * Maneja la apertura del modal de edición.
   * @function
   * @returns {void}
   */
  const handleOpenModalEdit = () => {
    if (anulacion)
      return warningToast(
        'No se puede editar un documento equivalente anulado',
        '',
        'top-center'
      );

    if (!!codigoUnico) {
      setOpenModalEdit(true);
    } else {
      setModalData(editErrorDataModal);
    }
  };

  /**
   * * Maneja la apertura del modal de envío de correo.
   * @function
   * @returns {void}
   */
  const handleOpenModalSend = () => {
    if (codigoUnico) {
      setOpenModalMail(true);
      setMailData({
        TipoDocumento: '31',
        Documento: requestCompany.documento,
        CodigoUnico: codigoUnico,
        NumeroDocumento: numero,
        RazonSocialEmpresa: requestCompany.razonsocial,
        RazonSocialCliente: nombreCliente,
        DocumentoId: id,
        TipoModalidad: 7,
      });
    } else {
      setModalData(mailErrorDataModal);
    }
  };

  /**
   * * Lista de objetos que representan botones con iconos y textos asociados.
   * @property {Function} onClick - La función que se ejecuta al hacer clic en el botón.
   * @property {ReactNode} icon - El icono React que se muestra en el botón.
   * @property {string} text - El texto asociado al botón.
   * @property {boolean} [isLoading] - Un indicador opcional que muestra si el botón está en estado de carga.
   */
  const renderIcon = [
    {
      onClick: changeStateFavorite,
      icon: favorito ? <StarIcon /> : <StarBorderIcon />,
      text: 'Favorito',
      isLoading: loadingStateEquivalentDoc === id,
      xs: 3,
    },
    {
      onClick: handlePayDocument,
      icon: (
        <img alt='Pagada' style={{ paddingBottom: '0.2em' }} src={imageMoney} />
      ),
      text: stateInvoice(equivalentDocDetail),
      isLoading: loadingStateEquivalentDocPaid === id,
      xs: 3,
    },
    // {
    //   onClick: handleOpenModalFile,
    //   icon: <VisibilityIcon />,
    //   text: "Ver",
    // }, // ! Falta implementar
    {
      onClick: handleOpenModalEdit,
      icon: <EditIcon />,
      text: 'Editar',
      xs: 3,
    },
    {
      onClick: handleOpenModalSend,
      icon: <EmailIcon />,
      text: 'Enviar',
      xs: 3,
    },
  ];

  /**
   * * Maneja la selección del tipo de nota.
   * @function
   * @param {string} type - El tipo de nota seleccionado.
   * @returns {void}
   */
  const noteTypeSelectionHandler = (type) => {
    if (type === 'ANU') {
      setOpenModalEdit(false);
      setOpenModalAnnulment(true);
    } else {
      setOpenModalEdit(false);
    }
  };

  /**
   * * Información sobre los diferentes modales relacionados con las notas de documentos.
   * @property {Function} onClose - La función que se ejecuta al cerrar el modal.
   * @property {boolean} open - Indica si el modal está abierto o cerrado.
   * @property {number} width - El ancho del modal en píxeles.
   * @property {Function} component - La función que devuelve el componente a renderizar en el modal.
   * @property {string} [title] - El título del modal (opcional).
   * @property {boolean} [showCloseButton] - Indica si se muestra el botón de cerrar (opcional).
   * @property {boolean} [disabled] - Indica si el modal está desactivado (opcional).
   * @property {boolean} [disableBackdropClick] - Indica si se debe desactivar el clic en el fondo del modal (opcional).
   */
  const documentNotesModalInformation = [
    {
      onClose: () => setOpenModalEdit(false),
      open: openModalEdit,
      width: 800,
      component: () => (
        <NoteTypeSelectionModal
          noteTypeSelectionHandler={noteTypeSelectionHandler}
        />
      ),
    },
    {
      onClose: () => setOpenModalAnnulment(false),
      open: openModalAnnulment,
      width: 800,
      // disabled: loadingNoteAnnulment,
      // disableBackdropClick: loadingNoteAnnulment,
      component: () => (
        <AnnulmentNoteModal
          handleCloseModal={() => setOpenModalAnnulment(false)}
        />
      ),
    },
    {
      onClose: () => setOpenModalMail(false),
      open: openModalMail,
      title: 'Envio de Documento Equivalente',
      width: 365,
      showCloseButton: false,
      component: () => (
        <ModalFileForm
          email={correoElectronico}
          data={mailData}
          isDocEquivalentMail
          type={'EQUIVALENT'}
        />
      ),
    },
  ];

  return (
    <Grid
      container
      alignItems='center'
      className={`${classes.root}  animate__animated animate__fadeIn animate__faster`}
    >
      {renderIcon.map((icon, index) => (
        <RenderIconButton key={icon.text} {...icon} />
      ))}
      {/* <FileModalComponent
        open={openModalFile}
        onCloseElement={() => setOpenModalFile(false)}
        closeText={"Cerrar"}
        paramFile={file}
      /> */}
      <ResponseModal {...modalData} onCloseElement={handleResponseModal} />

      {documentNotesModalInformation.map((modalInfo, index) => (
        <SimpleModal key={index} {...modalInfo} />
      ))}
    </Grid>
  );
};
