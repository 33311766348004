import { types } from '../types/types';

import { initialModalDataCreate } from '../views/EquivalentDocument/utils';

const initialState = {
  acquirerInformation: null,
  additionalDocInfo: null,
  additionalinfoBoxEquivalent: null,
  equivalentDocDetail: null,
  equivalentDocFilter: [],
  equivalentDocument: {
    equivalentDocumentItems: [],
    totalEquivalentDocumentsItems: 0,
  },
  loadingGetEquivalentDoc: false,
  loadingGetEquivalentDocDetail: false,
  loadingStateEquivalentDoc: false,
  loadingStateEquivalentDocPaid: false,
  selectedDocumentData: null,
  selectedDocumentID: null,
  totaldocuments: 0,
  noteEquivalentDocActive: false,
  showComponents: {
    showGrid: true,
    showCreate: false,
    showEdit: false,
    showDetailNote: false,
  },
  createEquivalentDocumentData: {
    completed: new Set(),
    activeStep: 0,
  },
  customerEquivalentDoc: null,
  initialLoadCreateEquivalentDoc: {
    activeResolutionsDoc: [],
    activeBranches: [],
    activeEquivalentDocTypes: [],
  },
  formErrors: [],
  equivalentDocumentForm: {
    favorite: false,
  },
  modalData: initialModalDataCreate,
  loadingReportEquivalentDoc: [],
  loadingEquivalentDocTypes: false,
  testIdEquivalentDoc: null,
};

export const equivalentDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_COMPONENTS_EQUIVALENT_DOC:
      return {
        ...state,
        showComponents: action.payload,
      };
    case types.SET_INITIAL_LOAD_CREATE_EQUIVALENT:
      return {
        ...state,
        initialLoadCreateEquivalentDoc: action.payload,
      };
    case types.SET_ERRORS_FORM_EQUIVALENT_DOC:
      return {
        ...state,
        formErrors: action.payload,
      };
    case types.GET_EQUIVALENT_DOC:
      return {
        ...state,
        equivalentDocFilter: action.payload,
        totaldocuments: action.payload1,
      };
    case types.LOADING_GET_EQUIVALENT_DOC:
      return {
        ...state,
        loadingGetEquivalentDoc: action.payload,
      };
    case types.LOADING_STATE_EQUIVALENT_DOC:
      return {
        ...state,
        loadingStateEquivalentDoc: action.payload,
      };
    case types.LOADING_STATE_EQUIVALENT_DOC_PAID:
      return {
        ...state,
        loadingStateEquivalentDocPaid: action.payload,
      };
    case types.SET_SELECTED_DOCUMENT_ID:
      return {
        ...state,
        selectedDocumentID: action.payload,
      };
    case types.GET_EQUIVALENT_DOC_DETAIL:
      return {
        ...state,
        equivalentDocDetail: action.payload,
      };
    case types.LOADING_GET_EQUIVALENT_DOC_DETAIL:
      return {
        ...state,
        loadingGetEquivalentDocDetail: action.payload,
      };
    case types.SET_SELECTED_DOCUMENT_DATA:
      return {
        ...state,
        selectedDocumentData: action.payload,
      };
    case types.SET_ACQUIRER_INFORMATION:
      return {
        ...state,
        acquirerInformation: action.payload,
      };
    case types.SET_EQUIVALENT_DOCUMENT_ITEMS:
      return {
        ...state,
        equivalentDocument: action.payload,
      };
    case types.SET_ADDITIONAL_DOC_INFO:
      return {
        ...state,
        additionalDocInfo: action.payload,
      };
    case types.SET_ADDITIONAL_INFO_BOX:
      return {
        ...state,
        additionalinfoBoxEquivalent: action.payload,
      };
    case types.SET_INFO_FOR_CREATING_MODAL:
      return {
        ...state,
        modalData: action.payload,
      };

    //? Modulo Creacion de Documentos Equivalentes
    case types.SET_CREATE_EQUIVALENT_DOCUMENT_DATA:
      return {
        ...state,
        createEquivalentDocumentData: action.payload,
      };
    case types.LOAD_CUSTOMER_EQUIVALENT_DOC:
      return {
        ...state,
        customerEquivalentDoc: action.payload,
      };
    case types.SET_EQUIVALENT_DOC_DATA:
      return {
        ...state,
        equivalentDocumentForm: action.payload,
      };
    case types.LOADING_REPORT_EQUIVALENT_DOC:
      return {
        ...state,
        loadingReportEquivalentDoc: action.payload,
      };
    case types.CLEAR_EQUIVALENT_DOC:
      return initialState;
    case types.SET_TEST_ID_EQUIVALENT_DOC_DATA:
      return {
        ...state,
        testIdEquivalentDoc: action.payload,
      };
    case types.LOADING_EQUIVALENT_DOC_TYPES:
      return {
        ...state,
        loadingEquivalentDocTypes: action.payload,
      };
    case types.NOTE_EQUIVALENT_DOC_ACTIVE:
      return {
        ...state,
        noteEquivalentDocActive: action.payload,
      };
    default:
      return state;
  }
};
