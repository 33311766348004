import React, { useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import SimpleReactValidator from 'simple-react-validator';

import { Grid, makeStyles } from '@material-ui/core';

import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import SelectForm from '../../../../components/select/select.component';

import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';
import {
  changeEquivalentDocComponentAction,
  loadNoteEquivalentDocDataAction,
} from '../../../../actions/equivalentDocAction';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    fontSize: 15,
    padding: '0px 30px 25px 30px !important',
    textAlign: 'justify',
  },
  title: {
    color: '#16B1F3',
    fontSize: 25,
    fontWeight: 600,
  },
  subtitle: {
    color: '#16B1F3',
    fontSize: 18,
  },
  containerTitle: {
    paddingBottom: '20px !important',
    paddingTop: '20px !important',
    textAlign: 'center',
  },
}));

/**
 * *Opciones de selección para componente de select.
 * @property {string} value - El valor de la opción.
 * @property {string} text - El texto que se muestra para la opción.
 */
const optionsSelect = [
  {
    value: 'ND',
    text: 'Nota Débito',
  },
  {
    value: 'NC',
    text: 'Nota Crédito',
  },
  {
    value: 'ANU',
    text: 'Anulación',
  },
];

export const NoteTypeSelectionModal = ({ noteTypeSelectionHandler }) => {
  const classes = useStyles();

  const [, forceUpdate] = useState();
  const [typeNote, setTypeNote] = useState('none');

  const dispatch = useDispatch();

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));

  const {
    descripcionNotaAjusteCredito,
    // descripcionNotaAjusteDebito,
    descripcionAjusteAnulacion,
    messageModalEditEquivalentDoc,
  } = useSelector(
    (state) => state.menuReducer.menuInvoice,
    (prev, next) => prev.menuInvoice === next.menuInvoice
  );

  /**
   * *Información sobre los diferentes tipos de notas.
   * @property {string} subtitle - El subtítulo asociado al tipo de nota.
   * @property {string} description - La descripción del tipo de nota.
   */
  const noteTypesInformation = [
    // {
    //   subtitle: 'Nota de ajuste de tipo Débito:',
    //   description: descripcionNotaAjusteDebito,
    // },
    {
      subtitle: 'Nota de Ajuste de tipo Crédito:',
      description: descripcionNotaAjusteCredito,
    },
    {
      subtitle: 'Nota de Ajuste Anulación:',
      description: descripcionAjusteAnulacion,
    },
  ];

  /**
   * * Maneja el clic en el botón de continuar.
   * * Valida el formulario y ejecuta el manejador de selección de tipo de nota si el formulario es válido.
   * @function
   * @returns {void}
   */
  const handleClickContinue = () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      noteTypeSelectionHandler(typeNote);
      if (typeNote !== 'ANU') {
        dispatch(changeEquivalentDocComponentAction('showEdit'));
        return;
      } else {
        dispatch(loadNoteEquivalentDocDataAction());
      }
    }
  };

  return (
    <Grid container spacing={1} className={`${classes.root}`}>
      <Grid item lg={12} xs={12} className={classes.containerTitle}>
        <span className={classes.title}>Editar documento equivalente</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div
          className={classes.subTitle}
          dangerouslySetInnerHTML={{
            __html: messageModalEditEquivalentDoc ?? '',
          }}
        />
      </Grid>
      {noteTypesInformation.map(({ description = '', subtitle }, index) => (
        <Grid key={index} item lg={12} xs={12}>
          <span className={classes.subtitle}>{subtitle} </span>
          <span> {description}</span>
        </Grid>
      ))}
      <Grid item lg={12} xs={12}>
        <span>¿Qué deseas hacer?</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems='center' justify='center'>
          <Grid item lg={6} xs={6}>
            <SelectForm
              label={''}
              name='ddlTypeNote'
              value={typeNote}
              options={optionsSelect.filter((option) => option.value !== 'ND')}
              validator={validator.current}
              validateOptions={'required|notNone'}
              nameValidator={'tipoRegimen'}
              onChange={(e) => setTypeNote(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems='center' justify='center'>
          <Grid item lg={6} xs={6}>
            <ButtonPrimary text='Continuar' onClick={handleClickContinue} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
